import React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Rewards from '../components/loyaltylion/rewards'

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Showing our appreciation for loyal custom | Mud Foods"
        description="Get rewarded for your purchases at Mud Foods"
      />
      <Container py={5}>
        <Heading as="h1">Pie Perks</Heading>
        <Rewards />
      </Container>
    </Layout>
  )
}

export default IndexPage
